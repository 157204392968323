import { AgGridAngular } from '@ag-grid-community/angular';
import { AllModules, CellValueChangedEvent, GridOptions } from '@ag-grid-enterprise/all-modules';
import { ApplicationRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { isNil } from 'lodash';
import { Observable, Subscription } from 'rxjs';

import { GlobalEventsService } from 'src/app/_core/global-events.service';
import { AgGridStateService } from 'src/app/_shared/ag-grid/ag-grid-state.service';
import { AgGridService } from 'src/app/_shared/ag-grid/ag-grid.service';
import { ItemCardGridService } from 'src/app/item-card/grid/item-card-grid.service';
import { ItemDataPoint } from 'src/app/item-card/grid/models/item-data-point.model';
import { ChartDataParams } from 'src/app/item-card/models/chart-data-params.model';

@Component({
  selector: 'agr-item-card-grid',
  templateUrl: './item-card-grid.component.html'
})
export class ItemCardGridComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('agGrid', { static: true }) agGrid: AgGridAngular;
  @Input() itemId: number;
  @Input() orderId: number;
  @Input() chartParams: ChartDataParams;
  @Input() refreshItemEvent: Observable<void>;
  refreshItemSubscription: Subscription;

  gridModules = AllModules;
  gridOptions: GridOptions;
  rowData: ItemDataPoint[] = [];
  gridStateInitiated = false;

  constructor(
    private agGridService: AgGridService,
    private agGridStateService: AgGridStateService,
    private applicationRef: ApplicationRef,
    private globalEventService: GlobalEventsService,
    private itemCardGridService: ItemCardGridService
  ) {}

  ngOnInit(): void {
    this.initializeGrid();
    this.refreshItemSubscription = this.refreshItemEvent.subscribe(() => this.updateGrid());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!isNil(changes.itemId) && changes.itemId.currentValue !== changes.itemId.previousValue) {
      if (!isNil(changes.itemId.currentValue)) {
        this.showGridLoadingOverlay();
      } else {
        this.emptyGrid();
      }
    }
    if (changes.itemId || changes.orderId || changes.chartParams) {
      this.updateGrid();
    }
  }

  ngOnDestroy(): void {
    this.refreshItemSubscription.unsubscribe();
  }

  onGridReady(): void {
    this.agGridService.addEnterBtnEvents(this.agGrid, 'item-card-grid');
  }

  initiateGridState(): void {
    const stateKey = 'item-data.grid';
    this.agGridStateService.loadGridState(stateKey, this.agGrid);
    this.agGridStateService.monitorChanges(stateKey, this.agGrid);
    this.gridStateInitiated = true;
  }

  onCellEdited(cellEvent: CellValueChangedEvent): void {
    this.itemCardGridService.updateItemDataPoint(this.itemId, this.chartParams, cellEvent.data).subscribe(() => {
      const rowNode = this.agGrid.api.getRowNode(cellEvent.node.id);
      this.itemCardGridService.getGridDataByPeriod(this.itemId, this.chartParams, this.orderId).subscribe((itemCardGridData) => {
        rowNode.setData(itemCardGridData.itemDataPoints[rowNode.rowIndex]);
        this.updateForecast(itemCardGridData.itemDataPoints);
        this.agGrid.api.refreshCells();
        this.globalEventService.refreshOtherItemCards(this.itemId);
      });
    });
  }

  private updateGrid(): void {
    if (isNaN(this.itemId)) {
      return;
    }
    this.getGridData();
  }

  private initializeGrid(): void {
    this.gridOptions = this.itemCardGridService.getGridOptions();
    this.gridOptions.floatingFiltersHeight = 0;
    this.agGridService.addCustomColumnMenu('item-data.grid', this.agGrid);
  }

  private getGridData(): void {
    this.itemCardGridService.getGridDataByPeriod(this.itemId, this.chartParams, this.orderId).subscribe((itemCardGridData) => {
      this.agGrid.api.setColumnDefs(itemCardGridData.colDefs);
      this.setColumnVisibility(itemCardGridData.itemDataPoints);
      if (!this.gridStateInitiated) {
        this.initiateGridState();
      }
      this.rowData = itemCardGridData.itemDataPoints;
      if (this.agGrid.api) {
        this.agGrid.api.hideOverlay();
      }
      this.applicationRef.tick(); // Refresh DOM when page is in background
    });
  }

  private emptyGrid(): void {
    this.agGrid.api.setRowData([]);
    this.applicationRef.tick();
    this.globalEventService.refreshOtherItemCards(this.itemId);
  }

  private updateForecast(itemDataPoints: ItemDataPoint[]): void {
    this.agGrid.api.forEachNode((rowNode, index) => {
      if ((rowNode.data as ItemDataPoint).forecast !== itemDataPoints[index].forecast) {
        rowNode.setData(itemDataPoints[index]);
      }
    });
  }

  private showGridLoadingOverlay(): void {
    if (this.agGrid && this.agGrid.api) {
      this.agGrid.api.showLoadingOverlay();
    }
  }

  /**
   * Hides/Shows columns so that only columns are visible that contain data, like in the chart.
   */
  private setColumnVisibility(itemDataPoints: ItemDataPoint[]): void {
    const columnsWithValues = itemDataPoints.reduce((acc, curr) => {
      Object.keys(curr).map((key) => acc.add(key));
      return acc;
    }, new Set<string>());
    this.agGrid.columnApi.getAllColumns().map((column) => {
      const visible = columnsWithValues.has(column.getId()) && column.isVisible();
      setTimeout(() => {
        this.agGrid.columnApi.setColumnVisible(column, visible);
      });
    });
  }
}
