import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { CustomAction } from 'src/app/_core/models/custom-action.model';
import { CustomActionsService } from 'src/app/nav/custom-actions/custom-actions.service';

@Component({
  selector: 'agr-custom-actions',
  templateUrl: './custom-actions.component.html'
})
export class CustomActionsComponent {
  @ViewChild('content', { static: true }) content;
  modal: NgbModalRef;

  customActions$: Observable<CustomAction[]>;
  selectedCustomAction: CustomAction;
  submitted = false;

  form: FormGroup = this.formBuilder.group({});

  constructor(private modalService: NgbModal, private formBuilder: FormBuilder, private customActionsService: CustomActionsService) {}

  open(): void {
    this.customActions$ = this.customActionsService.getAllCustomActions();
    this.selectedCustomAction = undefined;
    this.submitted = false;
    this.form = this.formBuilder.group({});
    this.modal = this.modalService.open(this.content);
  }

  onActionSelectionChanged(): void {
    this.setFormValues();
  }

  onExecute(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.setActionValuesFromForm();
    this.customActionsService.executeCustomAction(this.selectedCustomAction).subscribe();
    this.modal.close();
  }

  onClose(): void {
    this.modal.close();
  }

  /**
   * When an action is selected we setup the form based on the action parameters
   */
  private setFormValues(): void {
    const controlGroup = {};
    if (this.selectedCustomAction) {
      this.selectedCustomAction.customActionParameters.map((actionParam) => {
        controlGroup[actionParam.formName] = actionParam.required
          ? new FormControl(actionParam.value || '', Validators.required)
          : new FormControl(actionParam.value || '');
      });
    }
    this.form = this.formBuilder.group(controlGroup);
  }

  /**
   * When submitting the form we copy the form values (input fields)
   * to the action parameter model that is posted to the API
   */
  private setActionValuesFromForm(): void {
    this.selectedCustomAction.customActionParameters.map((actionParam) => {
      actionParam.value = this.form.get(actionParam.formName).value;
    });
  }
}
