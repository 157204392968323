<!-- Header, tools -->
<div *ngIf="isSidebar" class="agr-layout-sidebar-header agr-mini-tools">
  <span class="amt-area">
    <strong>
      <span class="amt-title">{{ 'ITEM_DETAILS' | agrTranslate }}</span>
    </strong>
  </span>
  <span class="amt-area">
    <button
      id="edit-mode-btn"
      class="amt-btn blue"
      (click)="toggleSidebarEditMode()"
      *ngIf="!sidebarEditMode && !isEmpty"
      ngbTooltip="{{ 'EDIT' | agrTranslate }}">
      <i class="fa fa-pencil"></i>
    </button>
    <button
      id="edit-mode-btn"
      class="btn btn-success pt-0 pb-0"
      style="font-size: small"
      (click)="toggleSidebarEditMode(); savePers()"
      *ngIf="sidebarEditMode">
      {{ 'DONE' | agrTranslate }}
    </button>

    <button class="amt-btn" (click)="close()">
      <i class="fa fa-close"></i>
    </button>
  </span>
</div>

<div *ngIf="!isEmpty">
  <agr-loading-dots *ngIf="isLoadingDetails" [color]="'yellow'" [size]="'big'" class="position-absolute h-100 w-100" style="z-index: 100">
  </agr-loading-dots>

  <form [formGroup]="form">
    <div [ngClass]="isSidebar ? 'agr-layout-sidebar-content' : 'container-fluid'">
      <div class="details-container" [ngClass]="{ 'edit-mode': sidebarEditMode }" [ngStyle]="{ opacity: isLoadingDetails ? '0.4' : '1' }">
        <!-- item Info -->
        <div data-cy="itemInfo" class="card" [hidden]="isHidden('_item') && !sidebarEditMode">
          <div class="card-header" [ngClass]="{ inactive: isHidden('_item') }">
            <span>{{ 'ITEM_INFO' | agrTranslate }}</span>
            <button type="button" class="btn visibility-btn" (click)="toggleGroup('_item')" *ngIf="sidebarEditMode">
              <i class="fa" [ngClass]="isHidden('_item') ? 'fa-eye-slash' : 'fa-eye'"></i>
            </button>
          </div>
          <div class="card-body">
            <ul class="list-group list-group-flush">
              <li
                *ngFor="let column of getGroupColumns('_item'); let index = index"
                class="list-group-item"
                [ngClass]="{ inactive: isHidden(column.name) }">
                <div
                  [attr.data-cy]="isSidebar ? 'sideBar_' + column.name : column.name"
                  [hidden]="isHidden(column.name) && !sidebarEditMode"
                  class="not-editable"
                  agr-details-non-editable-columns
                  [id]="column.name"
                  [column]="column"
                  [itemId]="itemId"></div>
                <button *ngIf="sidebarEditMode" type="button" class="btn visibility-btn" (click)="toggleColumn(column)">
                  <i class="fa" [ngClass]="isHidden(column.name) ? 'fa-eye-slash' : 'fa-eye'"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>

        <!-- Stock info -->
        <div data-cy="stockInfo" class="card" [hidden]="isHidden('_stock') && !sidebarEditMode">
          <div class="card-header" [ngClass]="{ inactive: isHidden('_stock') }">
            <span>{{ 'STOCK_INFO' | agrTranslate }}</span>
            <button
              *ngIf="sidebarEditMode"
              type="button"
              class="btn visibility-btn"
              (click)="toggleGroup('_stock')"
              data-cy="stock-info-visibility">
              <i class="fa" [ngClass]="isHidden('_stock') ? 'fa-eye-slash' : 'fa-eye'"></i>
            </button>
          </div>
          <div class="card-body">
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item"
                *ngFor="let column of getGroupColumns('_stock'); let index = index"
                [ngClass]="{ inactive: isHidden(column.name) }">
                <div
                  [attr.data-cy]="isSidebar ? 'sideBar_' + column.name : column.name"
                  [hidden]="isHidden(column.name) && !sidebarEditMode"
                  class="not-editable"
                  agr-details-non-editable-columns
                  [id]="column.name"
                  [column]="column"
                  [itemId]="itemId"></div>
                <button *ngIf="sidebarEditMode" type="button" class="btn visibility-btn" (click)="toggleColumn(column)">
                  <i class="fa" [ngClass]="isHidden(column.name) ? 'fa-eye-slash' : 'fa-eye'"></i>
                </button>
              </li>
              <li
                class="list-group-item d-flex justify-content-between"
                [ngClass]="{ inactive: isHidden('_exp_stock') }"
                *ngIf="expiringStockColumns.length && !(isHidden('_exp_stock') && !sidebarEditMode)">
                <span class="px-1" style="font-size: small">{{ 'EXPIRING_STOCK' | agrTranslate }}</span>
                <a *ngIf="pers.hideExpStock" class="mx-2 u-cursor-pointer" (click)="toggleExpStock()">
                  {{ 'SHOW_EXPIRING_STOCK' | agrTranslate }}
                  <i class="fa fa-angle-right"></i>
                </a>
                <a *ngIf="!pers.hideExpStock" class="me-2 u-cursor-pointer" (click)="toggleExpStock()">
                  {{ 'HIDE_EXPIRING_STOCK' | agrTranslate }}
                  <i class="fa fa-angle-down"></i>
                </a>
                <button *ngIf="sidebarEditMode" type="button" class="btn visibility-btn" (click)="toggleGroup('_exp_stock')">
                  <i class="fa" [ngClass]="isHidden('_exp_stock') ? 'fa-eye-slash' : 'fa-eye'"></i>
                </button>
              </li>
              <li
                class="list-group-item px-1"
                style="font-size: small"
                *ngFor="let stock of expiringStockColumns; let index = index"
                [ngClass]="{ inactive: isHidden('_exp_stock') }">
                <ul
                  class="list-inline d-flex justify-content-between w-100"
                  *ngIf="!pers.hideExpStock && !(isHidden('_exp_stock') && !sidebarEditMode)">
                  <li class="list-inline-item">
                    {{ 'EXPIRING_STOCK_UNITS' | agrTranslate }}: {{ findColumn(stock, '_exp_stock_units').value }}
                  </li>
                  <li class="list-inline-item" style="text-align: right">
                    {{ 'EXPIRY_DATE' | agrTranslate }}: {{ findColumn(stock, '_expire_date').value | agrFormat: 'date' }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <!-- Order routes -->
        <div class="card" [hidden]="isHidden('_order_route_info') && !sidebarEditMode">
          <div class="card-header justify-content-between" [ngClass]="{ inactive: isHidden('_order_route_info') }">
            <div *ngFor="let route of orderRoutesColumns; let index = index">
              <div *ngIf="index === orderRouteIndex" (click)="toggleNextOrderRoute()">
                <span *ngIf="orderRoutesColumns.length > 1" class="badge rounded-pill u-cursor-pointer bg-primary">
                  {{ orderRoutesColumns.length }}
                </span>
                <span class="me-2" [ngClass]="{ 'u-cursor-pointer': orderRoutesColumns.length > 1 }">
                  {{ 'ORDER_ROUTE_INFO' | agrTranslate }}:
                </span>
                <button
                  *ngIf="orderRoutesColumns.length > 1"
                  id="order-route-info-btn"
                  class="agr-small-dropdown-btn text-truncate"
                  type="button">
                  {{ findColumn(route, '_route_info').value }}
                  <i *ngIf="orderRoutesColumns.length > 1" class="fa fa-angle-right"></i>
                </button>
                <span *ngIf="orderRoutesColumns.length === 1">{{ findColumn(route, '_route_info')?.value }}</span>
              </div>
            </div>
            <button *ngIf="sidebarEditMode" type="button" class="btn visibility-btn" (click)="toggleGroup('_order_route_info')">
              <i class="fa" [ngClass]="isHidden('_order_route_info') ? 'fa-eye-slash' : 'fa-eye'"></i>
            </button>
          </div>
          <div class="card-body">
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item"
                *ngFor="let column of orderRoutesColumns[orderRouteIndex]; let index = index"
                [ngClass]="{ inactive: isHidden(column.name) }">
                <div
                  [attr.data-cy]="isSidebar ? 'sideBar_' + column.name : column.name"
                  [hidden]="isHidden(column.name) && !sidebarEditMode"
                  class="not-editable"
                  agr-details-non-editable-columns
                  [id]="column.name"
                  [column]="column"
                  [itemId]="itemId"></div>
                <button *ngIf="sidebarEditMode" type="button" class="btn visibility-btn" (click)="toggleColumn(column)">
                  <i class="fa" [ngClass]="isHidden(column.name) ? 'fa-eye-slash' : 'fa-eye'"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>

        <!-- Forecast settings -->
        <div class="card" [hidden]="isHidden('_forecast_settings') && !sidebarEditMode">
          <div class="card-header" [ngClass]="{ inactive: isHidden('_forecast_settings') }">
            <span>{{ 'FORECAST_SETTINGS' | agrTranslate }}</span>
            <button *ngIf="sidebarEditMode" type="button" class="btn visibility-btn" (click)="toggleGroup('_forecast_settings')">
              <i class="fa" [ngClass]="isHidden('_forecast_settings') ? 'fa-eye' : 'fa-eye-slash'"></i>
            </button>
          </div>
          <div class="card-body">
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item"
                *ngFor="let column of getGroupColumns('_forecast_settings'); let index = index"
                [ngClass]="{ inactive: isHidden(column.name) }">
                <div
                  [attr.data-cy]="isSidebar ? 'sideBar_' + column.name : column.name"
                  agr-details-non-editable-columns
                  *ngIf="!isEditable(column)"
                  [hidden]="isHidden(column.name) && !sidebarEditMode"
                  [id]="column.name"
                  [ngClass]="{ inactive: isHidden(column.name) }"
                  [column]="column"
                  [itemId]="itemId"></div>

                <div
                  [attr.data-cy]="isSidebar ? 'sideBar_' + column.name : column.name"
                  class="editable"
                  agr-details-date-columns
                  *ngIf="isEditable(column) && column.isDateColumn()"
                  [hidden]="isHidden(column.name) && !sidebarEditMode"
                  [id]="column.name"
                  [ngClass]="{ inactive: isHidden(column.name) }"
                  [column]="column"
                  [itemId]="itemId"
                  [formGroup]="form"
                  (changed)="columnChanged(column)"></div>

                <div
                  [attr.data-cy]="isSidebar ? 'sideBar_' + column.name : column.name"
                  class="editable"
                  agr-details-number-columns
                  *ngIf="isEditable(column) && column.isNumberColumn()"
                  [hidden]="isHidden(column.name) && !sidebarEditMode"
                  [id]="column.name"
                  [ngClass]="{ inactive: isHidden(column.name) }"
                  [column]="column"
                  [itemId]="itemId"
                  [formGroup]="form"
                  (changed)="columnChanged(column)"></div>

                <div
                  [attr.data-cy]="isSidebar ? 'sideBar_' + column.name : column.name"
                  class="editable"
                  agr-details-lookup-columns
                  *ngIf="isEditable(column) && column.isLookupColumn()"
                  [hidden]="isHidden(column.name) && !sidebarEditMode"
                  [id]="column.name"
                  [ngClass]="{ inactive: isHidden(column.name) }"
                  [column]="column"
                  [itemId]="itemId"
                  [formGroup]="form"
                  (changed)="columnChanged(column)"></div>

                <div
                  [attr.data-cy]="isSidebar ? 'sideBar_' + column.name : column.name"
                  class="editable"
                  agr-details-string-columns
                  *ngIf="isEditable(column) && column.isStringColumn()"
                  [hidden]="isHidden(column.name) && !sidebarEditMode"
                  [id]="column.name"
                  [ngClass]="{ inactive: isHidden(column.name) }"
                  [column]="column"
                  [itemId]="itemId"
                  [formGroup]="form"
                  (changed)="columnChanged(column)"></div>
                <button *ngIf="sidebarEditMode" type="button" class="btn visibility-btn" (click)="toggleColumn(column)">
                  <i class="fa" [ngClass]="isHidden(column.name) ? 'fa-eye-slash' : 'fa-eye'"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>

        <!-- Order Logic settings -->
        <div class="card" [hidden]="isHidden('_order_logic_settings') && !sidebarEditMode">
          <div class="card-header" [ngClass]="{ inactive: isHidden('_order_logic_settings') }">
            <span>{{ 'ORDER_LOGIC_SETTINGS' | agrTranslate }}</span>
            <button *ngIf="sidebarEditMode" type="button" class="btn visibility-btn" (click)="toggleGroup('_order_logic_settings')">
              <i class="fa" [ngClass]="isHidden('_order_logic_settings') ? 'fa-eye-slash' : 'fa-eye'"></i>
            </button>
          </div>
          <div class="card-body">
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item"
                *ngFor="let column of getGroupColumns('_order_logic_settings'); let index = index"
                [ngClass]="{ inactive: isHidden(column.name) }">
                <div
                  [attr.data-cy]="isSidebar ? 'sideBar_' + column.name : column.name"
                  agr-details-non-editable-columns
                  *ngIf="!isEditable(column)"
                  [hidden]="isHidden(column.name) && !sidebarEditMode"
                  [id]="column.name"
                  [column]="column"
                  [itemId]="itemId"></div>

                <div
                  [attr.data-cy]="isSidebar ? 'sideBar_' + column.name : column.name"
                  class="editable"
                  agr-details-date-columns
                  *ngIf="isEditable(column) && column.isDateColumn()"
                  [hidden]="isHidden(column.name) && !sidebarEditMode"
                  [id]="column.name"
                  [column]="column"
                  [itemId]="itemId"
                  [formGroup]="form"
                  (changed)="columnChanged(column)"></div>

                <div
                  [attr.data-cy]="isSidebar ? 'sideBar_' + column.name : column.name"
                  class="editable"
                  agr-details-number-columns
                  *ngIf="isEditable(column) && column.isNumberColumn()"
                  [hidden]="isHidden(column.name) && !sidebarEditMode"
                  [id]="column.name"
                  [column]="column"
                  [itemId]="itemId"
                  [formGroup]="form"
                  (changed)="columnChanged(column)"></div>

                <div
                  [attr.data-cy]="isSidebar ? 'sideBar_' + column.name : column.name"
                  class="editable"
                  agr-details-lookup-columns
                  *ngIf="isEditable(column) && column.isLookupColumn()"
                  [hidden]="isHidden(column.name) && !sidebarEditMode"
                  [id]="column.name"
                  [column]="column"
                  [itemId]="itemId"
                  [formGroup]="form"
                  (changed)="columnChanged(column)"></div>

                <div
                  [attr.data-cy]="isSidebar ? 'sideBar_' + column.name : column.name"
                  class="editable"
                  agr-details-string-columns
                  *ngIf="isEditable(column) && column.isStringColumn()"
                  [hidden]="isHidden(column.name) && !sidebarEditMode"
                  [id]="column.name"
                  [column]="column"
                  [itemId]="itemId"
                  [formGroup]="form"
                  (changed)="columnChanged(column)"></div>
                <button *ngIf="sidebarEditMode" type="button" class="btn visibility-btn btn-col" (click)="pers.toggleColumn(column.name)">
                  <i class="fa" [ngClass]="isHidden(column.name) ? 'fa-eye-slash' : 'fa-eye'"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>

        <!-- Other/Custom settings -->
        <div class="card" *ngIf="otherColumns.length > 0" [hidden]="isHidden('_other') && !sidebarEditMode">
          <div class="card-header" [ngClass]="{ inactive: isHidden('_other') }">
            <span>{{ 'OTHER_COLUMNS' | agrTranslate }}</span>
            <button *ngIf="sidebarEditMode" type="button" class="btn visibility-btn" (click)="toggleGroup('_other')">
              <i class="fa" [ngClass]="isHidden('other') ? 'fa-eye-slash' : 'fa-eye'"></i>
            </button>
          </div>
          <div class="card-body">
            <ul class="list-group list-group-flush">
              <li scope="col" *ngFor="let column of getGroupColumns('_other'); let index = index">
                <div
                  class="not-editable"
                  agr-details-non-editable-columns
                  *ngIf="!isEditable(column)"
                  [hidden]="isHidden(column.name) && !sidebarEditMode"
                  [id]="column.name"
                  [ngClass]="{ inactive: isHidden(column.name) }"
                  [column]="column"
                  [itemId]="itemId"></div>

                <div
                  class="editable"
                  agr-details-date-columns
                  *ngIf="isEditable(column) && column.isDateColumn()"
                  [hidden]="isHidden(column.name) && !sidebarEditMode"
                  [id]="column.name"
                  [ngClass]="{ inactive: isHidden(column.name) }"
                  [column]="column"
                  [itemId]="itemId"
                  [formGroup]="form"
                  (changed)="columnChanged(column)"></div>

                <div
                  class="editable"
                  agr-details-number-columns
                  *ngIf="isEditable(column) && column.isNumberColumn()"
                  [hidden]="isHidden(column.name) && !sidebarEditMode"
                  [id]="column.name"
                  [ngClass]="{ inactive: isHidden(column.name) }"
                  [column]="column"
                  [itemId]="itemId"
                  [formGroup]="form"
                  (changed)="columnChanged(column)"></div>

                <div
                  class="editable"
                  agr-details-lookup-columns
                  *ngIf="isEditable(column) && column.isLookupColumn()"
                  [hidden]="isHidden(column.name) && !sidebarEditMode"
                  [id]="column.name"
                  [ngClass]="{ inactive: isHidden(column.name) }"
                  [column]="column"
                  [itemId]="itemId"
                  [formGroup]="form"
                  (changed)="columnChanged(column)"></div>

                <div
                  class="editable"
                  agr-details-string-columns
                  *ngIf="isEditable(column) && column.isStringColumn()"
                  [hidden]="isHidden(column.name) && !sidebarEditMode"
                  [id]="column.name"
                  [ngClass]="{ inactive: isHidden(column.name) }"
                  [column]="column"
                  [itemId]="itemId"
                  [formGroup]="form"
                  (changed)="columnChanged(column)"></div>
                <button *ngIf="sidebarEditMode" type="button" class="btn visibility-btn" (click)="pers.toggleColumn(column.name)">
                  <i class="fa" [ngClass]="isHidden(column.name) ? 'fa-eye-slash' : 'fa-eye'"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <!-- Undelivered orders -->
        <div class="card undelivered">
          <div class="card-header">
            <div>
              <span
                class="badge rounded-circle"
                [ngClass]="{ 'bg-primary': undeliveredOrdersColumns.length, 'bg-secondary': !undeliveredOrdersColumns.length }">
                {{ undeliveredOrdersColumns.length }}
              </span>
              {{ 'UNDELIVERED_ORDERS' | agrTranslate }}
            </div>
          </div>
          <div class="card-body d-flex flex-column">
            <div class="card-header d-flex justify-content-around" *ngIf="!undeliveredOrdersColumns.length">
              {{ 'NO_UNDELIVERED_ORDERS' | agrTranslate }}
            </div>
            <ng-template #estimatedDeliveryPopContent>
              <i class="fa fa-circle circle-label text-success pe-1"></i>{{ 'FROM' | agrTranslate }} {{ today | agrFormat: 'date' }}:
              {{ 'UNDELIVERED_ESTIMATED_DELIVERY_INFO_FUTURE' | agrTranslate }}
              <br />
              <span *ngIf="orderLogicUndeliveredDays">
                <i class="fa fa-circle circle-label text-warning pe-1"></i>{{ 'TO' | agrTranslate }} {{ today | agrFormat: 'date' }}:
                {{ 'UNDELIVERED_ESTIMATED_DELIVERY_INFO_PAST' | agrTranslate }}
              </span>
              <br />
              <i class="fa fa-circle circle-label text-danger pe-1"></i> {{ 'TO' | agrTranslate }}
              {{ orderLogicUndeliveredDate | agrFormat: 'date' }}:
              {{ 'UNDELIVERED_ESTIMATED_DELIVERY_INFO_EXPIRED' | agrTranslate }}
              <span *ngIf="orderLogicUndeliveredDays">(-{{ orderLogicUndeliveredDays }} {{ 'DAYS' | agrTranslate }})</span>
            </ng-template>
            <table class="table table-borderless table-sm" *ngIf="undeliveredOrdersColumns.length">
              <tr class="border-bottom">
                <td scope="col">
                  {{ 'ESTIMATED_DELIVERY' | agrTranslate }}
                </td>
                <td scope="col">
                  {{ 'QUANTITY' | agrTranslate }}
                </td>
                <td scope="col">{{ 'REFERENCE_NO' | agrTranslate }}</td>
                <td scope="col">{{ 'EXPIRY_DATE' | agrTranslate }}</td>
              </tr>
              <tr *ngFor="let order of undeliveredOrdersColumns; let index = index">
                <td scope="col" class="align-middle">
                  <span class="p-0">
                    <button class="btn undelivered-btn" [ngbPopover]="estimatedDeliveryPopContent" popoverTitle="" container="body">
                      <i
                        class="fa fa-circle"
                        [ngClass]="isUndelivered(order) ? 'text-success' : isOutdated(order) ? 'text-danger' : 'text-warning'"></i>
                    </button>
                    {{ findColumn(order, '_undelivered_est_delivery').value | agrFormat: 'date' }}
                  </span>
                </td>
                <td id="item-undelivered-quantity-{{ index }}" scope="col">
                  <span class="p-0">
                    {{ findColumn(order, '_undelivered_qty').value }}
                  </span>
                </td>
                <td id="item-undelivered-purchase-reference-{{ index }}" scope="col">
                  <span class="p-0">
                    {{ findColumn(order, '_undelivered_reference_no').value }}
                  </span>
                </td>
                <td id="item-undelivered-expiry-date-{{ index }}" scope="col">
                  <span class="p-0">
                    {{ findColumn(order, '_undelivered_expire_date').value | agrFormat: 'date' }}
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<agr-no-data-to-display *ngIf="isEmpty" class="h-100 w-100"></agr-no-data-to-display>
